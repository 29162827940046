import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Checkbox,
  Form,
  Input,
  Typography,
  Avatar,
  Spin,
} from "antd";
import {
  LockOutlined,
  UserOutlined,
  AntDesignOutlined,
} from "@ant-design/icons";
import { detectMob } from "../helpers/helpers";

const { Title } = Typography;

const Login = () => {
//   let navigate = useNavigate();
  const [loading] = useState(false);

  const onFinish = (values) => {
  };

  return (
    <>
      <Row style={{ height: "100vh" }}>
        <Col
          xs={24}
          sm={24}
          md={4}
          offset={detectMob() ? null : 4}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            icon={<AntDesignOutlined />}
          />
          <Spin spinning={loading} tip={"Loading..."}>
            <Title level={3}>M2A-GARDERIE</Title>
            <br />
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                rules={[
                  { type: "email", message: "E-mail is invalid" },
                  { required: true, message: "E-mail is required" },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="E-mail"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Password is required" }]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <a className="login-form-forgot" href="">
                  Forgot password
                </a>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: "100%" }}
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Col>
        {detectMob() ? null : (
          <>
            <Col sm={24} md={4}></Col>
            <Col sm={0} md={12} style={{ backgroundColor: "green" }}></Col>
          </>
        )}
      </Row>
    </>
  );
};

export default Login;
